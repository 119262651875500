import cn from 'classnames';
import React, { CSSProperties, FC, forwardRef, Ref } from 'react';

import sectionStyle from './Section.module.scss';

interface Props {
  className?: string;
  children?: any;
  ref?: Ref<any>;
  mobileNoPadding?: boolean;
  style?: CSSProperties;
}

const Section: FC<Props> = forwardRef(({ children, className, mobileNoPadding = false, style, ...props }, ref) => {
  return (
    <section ref={ref} className={cn(sectionStyle.block, className)} style={style} {...props}>
      <div className={cn('container lg:px-6', { 'px-4': !mobileNoPadding })}>{children}</div>
    </section>
  );
});

Section.displayName = 'Section';
export default Section;
