import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowsSpin, faCircleNotch, faLoader, faSpinner, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

type SpinnerType = 'spinner' | 'spinner-third' | 'circle-notch' | 'arrows-spin' | 'loader';

interface Props {
  className?: string;
  iconClass?: string;
  type?: SpinnerType;
  size?: SizeProp;
}

const getIcon = (spinnerType: SpinnerType): IconProp => {
  switch (spinnerType) {
    case 'arrows-spin':
      return faArrowsSpin;
    case 'spinner-third':
      return faSpinnerThird;
    case 'circle-notch':
      return faCircleNotch;
    case 'loader':
      return faLoader;
    default:
      return faSpinner;
  }
};

const LoadingSpinner: FC<Props> = ({ className, type = 'spinner-third', iconClass = '', size = '2x' }) => {
  const { t } = useTranslation('common');

  return (
    <div className={className ?? 'fit flex items-center justify-center'} data-cy="loading-spinner">
      <FontAwesomeIcon
        className={iconClass}
        icon={getIcon(type)}
        size={size}
        spin
        title={t('common:loading.description')}
      />
    </div>
  );
};

export default LoadingSpinner;
